import React, { useEffect, useState, useCallback } from "react";

import WorkersTable from "../../../../shared/DataTable/WorkersTable";
import TopSearchBar from "../../../../shared/Workers/TopSearchBar";
import TabBtns from "../../../../shared/Workers/TabBtns";
import {
  GetWorkers,
  TerminateRevokeWorker,
} from "../../../../helper/personnel/workers";
import { TablePagination } from "../../../../components/pagination/pagination";
import { UserService } from "../../../../config/axiosUrl";
import AddNewWorkerModal from "../../../../shared/Modal/AddNewWorkerModal";
import { TextShimmer } from "../../../../components/shimmer/shimmer";
import { TableDefaultPage } from "../../../../components/default/defaultPage";
import {
  TableDefaultSvg,
  NoSearchTableSvg,
} from "../../../../assets/svg/tableDefaul";
import { NoTableOption } from "../../../../components/noOption/noOption";
import { SearchData } from "../../../../helper/global/global";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { NewTablePagination } from "../../../../components/pagination/newPagination";

const Workers = () => {
  let navigate = useNavigate();

  let { type } = useParams();

  const [filter, setFilter] = useState({
    Companies: [],
    Worksite: [],
    Areas: [],
  });

  const filterWorkers = (name, value) => {
    setFilter((prev) => {
      const index = prev[name].indexOf(value);
      if (index !== -1) {
        prev[name].splice(index, 1);
      } else {
        prev[name].push(value);
      }
      return { ...prev };
    });
  };

  const initialvalues = {
    data: [],
    selectedData: {},
    extra: {},
    pagination: {
      service: UserService,
      api: "/api/v1/personal/workers/get-lists",
      route: "/admin/personnel/workers",
      search: "",
      cursor: "",
      precursor: "",
      type: type,
    },
    status: type,
    isTabLoading: false,
    isLoading: false,
    isRowLoading: false,
    rowIndex: "",
    isShimmer: true,
  };

  const [workers, setWorkers] = useState(initialvalues);
  const [visibleWorkerModal, setVisibleWorkerModal] = useState(false);

  let deafaulPage = {
    disclaimer: "No Workers Found",
    description: "Create Your First Worker",
    buttonText: "Create New",
    svg: <TableDefaultSvg />,
    redirect: "",
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  const handleWorkers = {
    getWorker: async (value) => {
      console.log("the values are", value);
      navigate(
        `/admin/personnel/workers/1/1/1/${value?.type ? value?.type : type}`
      );
      setWorkers({
        ...workers,
        pagination: {
          ...workers?.pagination,
          type: value?.type ? value?.type : type,
        },
        status: value?.type ? value?.type : type,
        isTabLoading: value?.isType == false ? false : true,
      });

      const params = {
        type: value?.type ? value?.type : type,
      };

      let data = await GetWorkers(params);
      if (data?.status) {
        setWorkers({
          ...workers,
          pagination: {
            ...workers?.pagination,
            type: value?.type ? value?.type : type,
          },
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          isShimmer: false,
          isTabLoading: false,
        });
      }
    },

    search: async (e) => {
      const companyIds = filter.Companies.join(",");
      const worksiteIds = filter.Worksite.join(",");
      const areaIds = filter.Areas.join(",");

      const params = {
        value: e?.target?.value,
        api: `/api/v1/personal/workers/get-lists?company_ids=${companyIds}&worksite_ids=${worksiteIds}&area_ids=${areaIds}&type=${workers?.pagination?.type}`,
        service: UserService,
      };

      let data = await SearchData(params);

      if (data?.status) {
        setWorkers({
          ...workers,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          pagination: {
            ...workers?.pagination,
            search: e?.target?.value,
          },
        });
      }
    },

    add: () => {
      setVisibleWorkerModal(true);
      setWorkers({
        ...workers,
        selectedData: {},
      });
    },
    edit: (item, index) => {
      console.log("jjjjjjjji");
      setVisibleWorkerModal(true);
      if (item?.id) {
        setWorkers({
          ...workers,
          selectedData: { ...item, index: index },
        });
      }
    },
    delete: (item, index) => {
      setVisibleWorkerModal(true);
      if (item?.id) {
        setWorkers({
          ...workers,
          selectedData: { ...item, delete: true, index: index },
        });
      }
    },
    getType: async (type) => {
      const params = {
        type: type,
      };
      await handleWorkers?.getWorker(params);
    },
    terminatRevoke: async (status, id, index) => {
      setWorkers({
        ...workers,
        isRowLoading: true,
        rowIndex: parseInt(index),
      });
      const params = {
        id: id,
        status: status,
      };

      let data = await TerminateRevokeWorker(params);
      if (data?.status) {
        const getDataParams = {
          type: workers?.pagination?.type,
          isType: false,
        };
        let getData = await handleWorkers?.getWorker(getDataParams);
        if (getData?.status) {
          toast?.success(data?.message);
          setWorkers({
            ...workers,
            status: status,
            isRowLoading: false,
            rowIndex: "",
          });
        }
      } else {
        toast?.error(data?.message);
      }
    },
  };

  useEffect(() => {
    if (
      filter.Companies.length ||
      filter.Areas.length ||
      filter.Worksite.length
    ) {
      handleWorkers.search();
    } else {
      handleWorkers?.getWorker();
    }
  }, [filter]);

  return (
    <>
      {visibleWorkerModal && (
        <AddNewWorkerModal
          setVisibleWorkerModal={setVisibleWorkerModal}
          data={workers}
          setData={setWorkers}
        />
      )}
      <div className="p-6">
        {workers?.isShimmer ? (
          <>
            <div className="bg-white px-4 py-3">
              <TextShimmer data={{ gap: 15, line: 20 }} />
            </div>
          </>
        ) : (
          <>
            {workers?.data?.length <= 0 &&
            !workers?.pagination?.search &&
            !workers?.pagination?.type ? (
              <>
                <TableDefaultPage data={deafaulPage} />
              </>
            ) : (
              <>
                <TopSearchBar
                  data={workers}
                  setData={setWorkers}
                  handle={handleWorkers}
                  filter={filter}
                  filterWorkers={filterWorkers}
                />

                <div className="data-card py-3 my-3 mx-4">
                  <TabBtns
                    data={workers}
                    setData={setWorkers}
                    handle={handleWorkers}
                    singleUser={false}
                  />

                  <div className="table-data p-5 bg-white mt-5">
                    <div>
                      <div className="text-right">
                        <p className="text-[14px]">
                          <span className="text-[14px] text-[#a1a1a1]">
                            Total Number of
                            {workers?.pagination?.type == 1
                              ? " Active "
                              : workers?.pagination?.type == 3
                              ? " Terminated "
                              : " "}
                            Workers :
                          </span>
                          &nbsp;{workers?.extra?.totalItems}
                        </p>
                      </div>

                      <div className="lg:overflow-hidden overflow-x-scroll mt-4">
                        <WorkersTable
                          data={workers}
                          setData={setWorkers}
                          handle={handleWorkers}
                        />
                      </div>
                      {workers?.data?.length <= 0 &&
                      workers?.pagination?.search ? (
                        <>
                          <NoTableOption data={noSearchOption} />
                        </>
                      ) : (
                        <>
                          <NewTablePagination
                            data={workers}
                            setData={setWorkers}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Workers;
