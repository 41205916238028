import { Button, Collapse } from "antd";
import React, { Fragment, useMemo } from "react";
import { Receipt } from "../../../../../assets/svg";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import moment from "moment";
import GetWeekDayReport from "./GetWeekDayReport";
import { useState, useEffect } from "react";
import * as XLSX from "xlsx";

const { Panel } = Collapse;

const getTotals = (value) => {
  if (value > -1 && value !== 0) {
    const floatValue = parseFloat(value);
    return floatValue ? floatValue.toFixed(1) : value;
  }

  if (value < 0 || (!value && value !== 0)) {
    return "-";
  }
  return value;
};

const SummaryCollapsible = ({ dateRange, summary, actives, terminateds }) => {
  const active = actives.data;

  console.log("active,active", active);
  const terminated = terminateds.data;

  const [sortOrder, setSortOrder] = useState("");
  const [mergedData, setMergedData] = useState([]);

  useEffect(() => {
    const merged = summary.worksites.reduce((prev, next) => {
      return [
        ...prev,
        ...next.worker_details.map((item) => {
          // Extract specific expenses based on expense_type
          const cocontribution = item?.employee_expenses_details?.find(
            (expense) => expense.expense_type === 8
          );
          const misc = item?.employee_expenses_details?.find(
            (expense) => expense.expense_type === 13
          );
          const employeeExpense = item?.employee_expenses_details?.find(
            (expense) => expense.expense_type === 10
          );
          const siteDonation = item?.employee_expenses_details?.find(
            (expense) => expense.expense_type === 12
          );

          return {
            ...item,
            timesheet_ids: next?.worksite_timesheet,
            job_no: next.job_no,
            cocontribution: cocontribution
              ? { name: cocontribution.name, value: cocontribution.rate }
              : null,
            misc: misc ? { name: misc.name, value: misc.rate } : null,
            employee_expense: employeeExpense
              ? { name: employeeExpense.name, value: employeeExpense.value }
              : null,
            site_donation: siteDonation
              ? { name: siteDonation.name, value: siteDonation.donation }
              : null,
          };
        }),
      ];
    }, []);

    setMergedData(merged);
  }, [summary]);

  const toggleSortOrder = () => {
    const order =
      !sortOrder || sortOrder === "desc"
        ? "asc"
        : sortOrder === "asc"
        ? "desc"
        : "";

    const sorted = mergedData.sort((a, b) =>
      sortOrder === "asc"
        ? a.user.name.localeCompare(b.user.name)
        : b.user.name.localeCompare(a.user.name)
    );

    // console.log("the sorted data", sorted);

    setMergedData([...sorted]);

    setSortOrder(order);
  };

  // console.log("sortOrder:", sortOrder);

  const [allowanceHeaders, allowanceValues] = useMemo(() => {
    const headers = [];
    let values = {};

    summary.worksites.forEach((worksite) => {
      worksite.worker_details.forEach((worker) => {
        if (worker?._id) {
          values[worker._id] = {};

          worker?.payroll_site_allowance_data?.forEach((item) => {
            if (
              item?.payroll_site_allowance?.summary &&
              !headers.includes(item?.payroll_site_allowance?.summary)
            ) {
              headers.push(item.payroll_site_allowance.summary);

              values[worker._id] = {
                ...values[worker._id],
                [item?.payroll_site_allowance?.summary]:
                  item?.payroll_site_allowance?.hourly_rate,
              };
            }
          });

          worker?.payroll_task_allowance_payroll_category_datas?.forEach(
            (item) => {
              item?.payroll_task_allowance?.forEach((child) => {
                if (child?.summary && !headers.includes(child.summary)) {
                  headers.push(child.summary);
                  values[worker._id] = {
                    ...values[worker._id],
                    [child.summary]: child.hourly_rate,
                  };
                }
              });
            }
          );

          worker?.payroll_category_allowance_payroll_category_datas?.forEach(
            (item) => {
              item.payroll_category_allowance.forEach((child) => {
                if (child?.summary && !headers.includes(child.summary)) {
                  headers.push(child.summary);
                  values[worker._id] = {
                    ...values[worker._id],
                    [child.summary]: child.hourly_rate,
                  };
                }
              });
            }
          );
        }
      });
    });
    return [headers, values];
  }, [summary]);

  const handleExportTerminated = () => {
    if (terminated && terminated.length > 0) {
      const titleterminated = ["Terminated Workers"];
      const headerRow = [
        "Emp. Co./Last Name",
        "Emp. First Name",
        "Payroll Category",
        "Job No",
        "Cust. Co./Last Name",
        "Cust. First Name",
        "Notes",
        "Date",
        "Units",
        "Employee Id",
        "Emp Designation",
        "Co-Contribution",
        "Employee Purchase",
        "Site Donation",
        "Misc",
        "Emp RecordId",
        "Start/Stop Time",
        "Customer Card Id",
        "Customer Record Id ",
      ];
      const subHeaderRow = ["", "", ""];
      const dataRows = [];

      terminated.forEach((item) => {
        item.worksites.forEach((worksiteData) => {
          worksiteData.worker_details.forEach((terminatedItem, index) => {
            const cocontributions =
              terminatedItem?.employee_expenses_details?.find(
                (expense) => expense.expense_type === 8
              );
            const misc = terminatedItem?.employee_expenses_details?.find(
              (expense) => expense.expense_type === 13
            );
            const employeeExpense =
              terminatedItem?.employee_expenses_details?.find(
                (expense) => expense.expense_type === 10
              );
            const siteDonation =
              terminatedItem?.employee_expenses_details?.find(
                (expense) => expense.expense_type === 12
              );
            const Name = terminatedItem.user.name;
            const payroll = "-";
            const date = new Date(
              terminatedItem.user.created_at
            ).toLocaleDateString("en-GB");

            const cardId = terminatedItem.user.employee_id;
            const companyName = item.name;
            const JobId = worksiteData?.job_no;
            const cusFistName = "-";
            const cusLastName = "-";
            const notes = "-";
            const units = "-";
            const RecordId = "-";
            const Startstop = "-";
            const cuscardId = "-";
            const cusrecordiD = "-";
            const empDesignationName =
              terminatedItem.worker_designation_data?.name || "N/A";
            const cocontribution = cocontributions?.rate || "N/A";
            const employeePurchase = employeeExpense?.value || "N/A";
            const SiteDonation = siteDonation?.donation || "N/A";
            const Misc = misc?.rate || "N/A";

            dataRows.push([
              companyName,
              Name,
              payroll,
              JobId,
              cusLastName,
              cusFistName,
              notes,
              date,
              units,
              cardId,
              empDesignationName,
              cocontribution,
              employeePurchase,
              SiteDonation,
              Misc,
              RecordId,
              Startstop,
              cuscardId,
              cusrecordiD,
            ]);
          });
        });
      });

      const workbook = XLSX.utils.book_new();
      const columnWidths = [
        { wch: 20 }, // Emp. Co./Last Name
        { wch: 20 }, // Emp. First Name
        { wch: 15 },
        { wch: 15 }, // Emp. Co./Last Name
        { wch: 20 }, // Emp. First Name
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      const worksheet = XLSX.utils.aoa_to_sheet([
        titleterminated,
        subHeaderRow,
        headerRow,
        ...dataRows,
      ]);
      worksheet["!cols"] = columnWidths;
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "FilteredTerminatedWorkerData"
      );

      XLSX.writeFile(workbook, "filterd_terminated_workers.xlsx");
    } else {
      console.error("retrived is undefined or empty");
    }
  };

  const handleExportToActive = () => {
    // Assuming retrived is defined somewhere in your code
    if (active && active.length > 0) {
      const titleactive = ["Active Workers"];
      const headerRow = [
        "Emp. Co./Last Name",
        "Emp. First Name",
        "Payroll Category",
        "Job",
        "Cust. Co./Last Name",
        "Cust. First Name",
        "Notes",
        "Date",
        "Units",
        "Employee Id",
        "Emp Designation",
        "Co-Contribution",
        "Employee Purchase",
        "Site Donation",
        "Misc",
        "Emp RecordId",
        "Start/Stop Time",
        "Customer Card Id",
        "Customer Record Id ",
      ];
      const subHeaderRow = ["", "", ""];
      const dataRows = [];
      active.forEach((item) => {
        item.worksites.forEach((worksiteData) => {
          worksiteData.worker_details.forEach((activeItem, index) => {
            const cocontributions = activeItem?.employee_expenses_details?.find(
              (expense) => expense.expense_type === 8
            );
            const misc = activeItem?.employee_expenses_details?.find(
              (expense) => expense.expense_type === 13
            );
            const employeeExpense = activeItem?.employee_expenses_details?.find(
              (expense) => expense.expense_type === 10
            );
            const siteDonation = activeItem?.employee_expenses_details?.find(
              (expense) => expense.expense_type === 12
            );
            const Name = activeItem.user.name;
            const payroll = "-";
            const date = new Date(
              activeItem.user.created_at
            ).toLocaleDateString("en-GB");

            const cardId = activeItem.user.employee_id;
            const companyName = item.name;
            const job = worksiteData.job_no;
            const cusFistName = "-";
            const cusLastName = "-";
            const notes = "-";
            const units = "-";
            const RecordId = "-";
            const Startstop = "-";
            const cuscardId = "-";
            const empDesignationName =
              activeItem.worker_designation_data?.name || "N/A";
            const cocontribution = cocontributions?.rate || "N/A";
            const employeePurchase = employeeExpense?.value || "N/A";
            const SiteDonation = siteDonation?.donation || "N/A";
            const Misc = misc?.rate || "N/A";
            const cusrecordiD = "-";

            dataRows.push([
              companyName,
              Name,
              payroll,
              job,
              cusLastName,
              cusFistName,
              notes,
              date,
              units,
              cardId,
              empDesignationName,
              cocontribution,
              employeePurchase,
              SiteDonation,
              Misc,
              RecordId,
              Startstop,
              cuscardId,
              cusrecordiD,
            ]);
          });
        });
      });

      const workbook = XLSX.utils.book_new();
      const columnWidths = [
        { wch: 20 }, // Emp. Co./Last Name
        { wch: 20 }, // Emp. First Name
        { wch: 15 },
        { wch: 15 }, // Emp. Co./Last Name
        { wch: 20 }, // Emp. First Name
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        // Payroll Category
        // ... (define widths for other columns)
      ];
      const worksheet = XLSX.utils.aoa_to_sheet([
        titleactive,
        subHeaderRow,
        headerRow,
        ...dataRows,
      ]);
      worksheet["!cols"] = columnWidths;
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "FilteredActiveWorkerData"
      );

      XLSX.writeFile(workbook, "filtered_active_workers.xlsx");
    } else {
      console.error("retrived is undefined or empty");
    }
  };

  const handleExportToExcel = () => {
    const headerRow = [
      "Last Name",
      "First Name",
      "Payroll Category",
      "Job No",
      "Employee Id",
      "Employment Designation",
      "Co-Contribution",
      "Employee Purchase",
      "Site Donation",
      "Misc",
    ];
    const subHeaderRow = ["", "", "", "", "", "", "", "", "", ""];
    const dataRows = [];

    dateRange.range.forEach((date) => {
      const formattedDate = moment(date).format("DD MMM YYYY");
      const formattedDay = moment(date).format("dddd");
      headerRow.push(`${formattedDate}  ${formattedDay}`);
      subHeaderRow.push("N                OT                DT ");
    });

    headerRow.push("Totals", "Allowances", "Accruals");
    subHeaderRow.push(
      "GT                N                OT               DT              TOD",
      "RDO(Accr  &  Taken)",
      "PL   PH"
    );

    mergedData.forEach((item) => {
      const space40px = "\u00A0".repeat(12);
      const gtValue = getTotals(
        item?.totals?.normal_time +
          item?.totals?.over_time +
          item?.totals?.double_time
      );
      console.log("item?.timesheetData", item);
      const daysOfWeek = [
        "Saturday",
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
      ];

      const worktime = daysOfWeek.map((day) => {
        const dayData = item?.timesheetData?.[day] || {};

        if (
          !dayData.normal_time &&
          !dayData.over_time &&
          !dayData.double_time
        ) {
          return "-";
        }
        return ` N(${getTotals(dayData.normal_time || 0)}) OT(${getTotals(
          dayData.over_time || 0
        )}) DT(${getTotals(dayData.double_time || 0)})`;
      });

      const totalsCell = [
        getTotals(gtValue || "-"),
        getTotals(item?.totals?.normal_time || "-"),
        getTotals(item?.totals?.over_time || "-"),
        getTotals(item?.totals?.double_time || "-"),
        getTotals(item?.totals?.ordinary_days || "-"),
      ]
        .map((value) => value.padEnd(3))
        .join(space40px);

      const rowData = [
        item?.user?.last_name || "-",
        item.user?.name || "-",
        item?.payroll_category_allowance_payroll_category_datas?.[0]
          ?.payroll_category_allowance?.[0]?.name || "-",
        item?.job_no,
        item?.user?.employee_id || "-",
        item?.worker_designation_data?.name || "N/A",
        item?.cocontribution?.value || "N/A",
        item?.employee_expense?.value || "N/A",
        item?.site_donation?.value || "N/A",
        item?.misc?.value || "N/A",
        ...(worktime && worktime.length > 0 ? worktime : ["-"]),
        totalsCell,
        getTotals(item?.totalAccrual || "-"),
        getTotals(item?.totals?.PL || "-"),
        getTotals(item?.totals?.PH || "-"),
      ];

      dataRows.push(rowData);
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([
      headerRow,
      subHeaderRow,
      ...dataRows,
    ]);

    // Adjust column widths if needed
    const columnWidths = headerRow.map(() => ({ wpx: 150 }));

    columnWidths[headerRow.indexOf("Totals")] = { wpx: 260 };
    columnWidths[headerRow.indexOf("Allowances")] = { wpx: 160 };
    columnWidths[headerRow.indexOf("Accruals")] = { wpx: 160 };
    worksheet["!cols"] = columnWidths;

    XLSX.utils.book_append_sheet(workbook, worksheet, "FilteredSummaryData");

    XLSX.writeFile(workbook, "filtered_summary_data.xlsx");
  };

  return (
    <Collapse
      className="border-none p-0 m-[0px] "
      accordion
      defaultActiveKey={["0"]}
      expandIcon={({ isActive }) =>
        isActive ? <FaAngleUp /> : <FaAngleDown />
      }
    >
      <Panel
        header={
          <span className="inline-flex gap-8 ">
            {/* <p className="font-semibold text-lg">#2312</p> */}
            <p className="font-semibold text-lg">{summary.name}</p>
          </span>
        }
        style={{
          margin: "5px",
        }}
        className="border-none p-0 m-[0px] "
      >
        <span className="flex justify-end ">
          <div className="flex gap-3">
            <Button
              className="flex items-center gap-1 border border-[#001C33]"
              onClick={handleExportToExcel}
            >
              <Receipt />
              Export to Excel
            </Button>

            <Button
              className="border border-[#001C33]"
              onClick={handleExportTerminated}
            >
              Export Terminated
            </Button>
            <Button
              className="border border-[#001C33]"
              onClick={handleExportToActive}
            >
              Export Active
            </Button>
          </div>
        </span>

        <div className="w-full overflow-x-scroll summary-detailed-table-container">
          <table className="timesheet-detailed-table ">
            <thead className="timesheet-detailed-table-head-container">
              <tr className="timesheet-detailed-table-main-head">
                <th>
                  <div className="w-[8vw] font-semibold text-center">
                    Last Name
                    {/* {sortOrder === "asc"
                      ? " ▲"
                      : sortOrder === "desc"
                      ? " ▼"
                      : ""} */}
                  </div>
                </th>
                <th onClick={toggleSortOrder}>
                  <div className="w-[8vw] font-semibold text-center">
                    First Name
                    {sortOrder === "asc"
                      ? " ▲"
                      : sortOrder === "desc"
                      ? " ▼"
                      : ""}
                  </div>
                </th>
                <th>
                  <div className="w-[8vw] font-semibold text-center">
                    Payroll Category
                  </div>
                </th>
                <th>
                  <div className="w-[8vw] font-semibold text-center">
                    Job No
                  </div>
                </th>
                <th>
                  <div className="w-[5vw] font-semibold">Employee Id</div>
                </th>
                <th>
                  <div className="w-[5vw] font-semibold">
                    Employee Designation
                  </div>
                </th>
                <th>
                  <div className="w-[5vw] font-semibold">Co-Contribution</div>
                </th>
                <th>
                  <div className="w-[5vw] font-semibold">Employee Purchase</div>
                </th>
                <th>
                  <div className="w-[5vw] font-semibold">Site Donation</div>
                </th>
                <th>
                  <div className="w-[5vw] font-semibold">Misc</div>
                </th>
                {dateRange.range.map((date) => (
                  <th colSpan={3} key={date}>
                    <div>{moment(date).format("DD MMM YYYY")}</div>
                    <div>{moment(date).format("dddd")}</div>
                  </th>
                ))}
                <th colSpan={5}>Totals</th>
                <th colSpan={allowanceHeaders.length}>Allowances</th>
                <th colSpan={3}>Accruals</th>
              </tr>
              <tr className="timesheet-detailed-table-secondary">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>Rate</th>
                <th>Value</th>
                <th>Donation</th>
                <th>Rate</th>

                {dateRange.range.map((item) => (
                  <Fragment key={item}>
                    <th>
                      <p className="pl-2 font-semibold">N</p>
                    </th>
                    <th>OT</th>
                    <th>
                      <p className="pr-2 font-semibold">DT</p>
                    </th>
                  </Fragment>
                ))}

                <th>
                  <p className="pl-2 font-semibold">GT</p>
                </th>
                <th>N</th>
                <th>OT</th>
                <th>DT</th>
                <th>
                  <p className="pr-2 font-semibold">TOD</p>
                </th>
                {allowanceHeaders.map((item) => (
                  <th>
                    <p className="font-semibold">{item}</p>
                  </th>
                ))}
                <th>
                  <div className="w-[9vw] font-semibold">RDO(Accr & Taken)</div>
                </th>
                <th>PL</th>
                <th>PH</th>
              </tr>
            </thead>
            <tbody className="timesheet-detailed-table-body">
              {mergedData.length > 0 ? (
                [...mergedData].map((item) => {
                  return item?._id ? (
                    <tr
                      className="border border-b-[#E9E9E9] border-2"
                      key={item?._id}
                    >
                      <td className="">{item.user.last_name || "N/A"}</td>
                      <td className="">{item.user.name}</td>
                      <td>
                        {item?.workers_payroll_category?.[0]?.payroll_category
                          ?.name || "N/A"}
                      </td>
                      <td className="">{item.job_no}</td>
                      <td>{item?.user?.employee_id}</td>
                      <td>{item?.worker_designation_data?.name || "N/A"}</td>
                      <td>{item?.cocontribution?.value || "N/A"}</td>
                      <td>{item?.employee_expense?.value || "N/A"}</td>
                      <td>{item?.site_donation?.value || "N/A"}</td>
                      <td>{item?.misc?.value || "N/A"}</td>
                      {dateRange.range.map((date) => (
                        <GetWeekDayReport
                          date={date}
                          parent={item}
                          key={date}
                        />
                      ))}

                      {/* GT */}
                      <td>
                        <p className="pl-2">
                          {getTotals(item?.totals?.gross_time)}
                        </p>
                      </td>
                      {/* N */}
                      <td>{getTotals(item?.totals?.normal_time)}</td>
                      {/* OT */}
                      <td>{getTotals(item?.totals?.over_time)}</td>
                      {/* DT */}
                      <td>{getTotals(item?.totals?.double_time)}</td>
                      {/* TOD */}
                      <td>
                        <p className="pr-2">
                          {getTotals(item?.totals?.ordinary_days)}
                        </p>
                      </td>

                      {allowanceHeaders.map((nested) => (
                        <td>{allowanceValues[item._id][nested] || "-"}</td>
                      ))}
                      {/* RDO(Accr & Taken) */}
                      <td>{item?.totalAccrual || "-"}</td>
                      <td>{item?.totals?.PL || "-"}</td>
                      <td>{item?.totals?.PH || "-"}</td>
                    </tr>
                  ) : (
                    <Fragment key={item?._id} />
                  );
                })
              ) : (
                <tr>
                  <td colSpan="22">No data found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Panel>
    </Collapse>
  );
};

export default SummaryCollapsible;
