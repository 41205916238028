import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Input, Select, Button, TimePicker } from "antd";
import { BsTrash3 } from "react-icons/bs";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import {
  FaArrowLeft,
  FaArrowRight,
  FaEdit,
  FaPlus,
  FaTrash,
} from "react-icons/fa";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CreateShiftDetails,
  EditShiftDetails,
  GetShift,
} from "../../../../../helper/worksite/worksiteDetails.js/timesheet";
import {
  GetWorkSite,
  GetWorkSiteById,
} from "../../../../../helper/worksite/worksite";
import { ButtonSpinner } from "../../../../../components/spinners";
import { GetWorkers } from "../../../../../helper/personnel/workers";
import { GetPayrollCatagory } from "../../../../../helper/setup/payroll/payrollcatagory";
import {
  GetAreaById,
  GetAreas,
} from "../../../../../helper/worksite/worksiteDetails.js/areas";
import { GetWorksiteData } from "../../../../../helper/worksite/worksiteDetails.js/worksitedetails";
import { GetTasks } from "../../../../../helper/worksite/worksiteDetails.js/task";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";

const { TextArea } = Input;

const workDoneInit = {
  area: "",
  spent: "",
  task: "",
};
const initialvalue = {
  work_done: [{ ...workDoneInit }],
};
const AddTimesheetDetails = () => {
  let navigate = useNavigate();

  let path = useLocation();
  let { id } = useParams();
  let { timesheet } = useParams();
  let { shift } = useParams();
  const allPrams = useParams();

  let search = window.location.search;
  let params = new URLSearchParams(search);
  const queryParams = new URLSearchParams(path.search);
  const summary = queryParams.get("summary") === "true";
  const workerId = queryParams.get("workerId");
  const workerName = queryParams.get("workerName");

  let activeDate = params.get("date");

  const shiftDetailsOption = [
    {
      value: "Rostered",
      label: "Rostered",
    },
    {
      value: "Working",
      label: "Working",
    },
    {
      value: "Finished",
      label: "Finished",
    },
    {
      value: "Complete",
      label: "Complete",
    },
    {
      value: "Absent",
      label: "Absent",
    },
  ];

  const completionOpt = [
    {
      value: "Sent home",
      label: "Sent home",
    },
    {
      value: "Left on own accord",
      label: "Left on own accord",
    },
  ];

  const breakOption = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ];

  const initialShiftDeatils = {
    id:
      path?.pathname ==
      `/admin/worksite/edit-timesheet-details/${id}/${timesheet}/${shift}`
        ? shift
        : "",
    worksite_id: id,
    worksite_timesheet_id: timesheet,
    worksite_name: "",
    area_id: "",
    worksheet_id: timesheet,
    isSave:
      path?.pathname ==
      `/admin/worksite/edit-timesheet-details/${id}/${timesheet}/${shift}`
        ? 0
        : 1,
    worker_data: [
      {
        worker_id: summary ? workerId : "",
        timesheet_status: "",
        shift_date: "",
        started: "",
        break: "",
        finished: "",
        completion_type: "",
        payroll_category_id: "",
        worksite_timesheet_workdone: [
          {
            id: "",
            area_id: "",
            task_id: "",
            spent: "",
          },
        ],
        notes: "",
      },
    ],
    errors: {
      worker_id: "",
      timesheet_status: "",
      started: "",
      shift_date: "",
      finished: "",
      break_type: "",
      payroll_category_id: "",
      wdone_area: "",
      wdone_task: "",
      wdone_spent: "",
      errorIndex: null,
      noteVisibility: false,
    },
    isInduction: true,
    isPayrollCategory: true,
    isLoading: false,
    isShimmer: true,
  };

  const initialWorksite = {
    option: [],
    selOption: null,
  };

  const initialPayrollCat = {
    option: [],
    selOption: null,
  };

  const initialAreas = {
    option: [],
    selOption: null,
  };

  const initialTasks = {
    option: [],
    selOption: null,
  };

  const [shiftDetails, setShiftDetails] = useState(initialShiftDeatils);

  const [workerOption, setWorkerOption] = useState(initialWorksite);
  const [parollCatOpt, setParollCatOpt] = useState(initialPayrollCat);
  const [areasOpt, setAreaOpt] = useState(initialAreas);
  const [taskOpt, setTaskOpt] = useState(initialTasks);

  let worker_id = useRef([]);
  let timesheet_status = useRef([]);
  let started = useRef([]);
  let shift_date = useRef([]);
  let finished = useRef([]);
  let break_type = useRef([]);
  let completion_type = useRef([]);
  let payroll_category_id = useRef([]);

  let area_id = useRef([]);
  let task_id = useRef([]);
  let spent = useRef([]);

  const handleShiftDetails = {
    getShift: async (getShift) => {
      const params = {
        id: getShift?.id,
        worksheet_id: getShift?.worksheet_id,
        cursor: getShift?.cursor,
        take: getShift?.take,
        previous: getShift?.previous,
        search: getShift?.search,
      };

      let data = await GetShift(params);

      if (data?.status) {
        let allData = [];
        let allworksite_timesheet_workdone = [];
        let workOpt = [];
        let payCat = [];
        let areaOpt = [];
        let taskOpt = [];

        data?.data?.data?.map((sdetails, index) => {
          sdetails?.users?.map((worker, wind) => {
            workOpt?.push({
              value: worker?._id,
              label: worker?.name,
            });
          });

          sdetails?.payroll_category?.map((proll, pind) => {
            payCat?.push({
              value: proll?._id,
              label: proll?.name,
            });
          });

          let start_time;
          if (sdetails?.started >= 10) {
            start_time = String(parseFloat(sdetails?.started)?.toFixed(2));
          } else {
            start_time = String(
              "0" + parseFloat(sdetails?.started)?.toFixed(2)
            );
          }

          let finished_time;
          if (sdetails?.finished >= 10) {
            finished_time = String(parseFloat(sdetails?.finished)?.toFixed(2));
          } else {
            finished_time = String(
              "0" + parseFloat(sdetails?.finished)?.toFixed(2)
            );
          }

          sdetails?.works?.map((wDone, ind) => {
            allworksite_timesheet_workdone?.push({
              id: wDone?._id,
              area_id: wDone?.area_id,
              task_id: wDone?.task_id,
              spent: wDone?.spent,
            });
          });

          sdetails?.works?.map((wDone, ind) => {
            areaOpt?.push({
              value: wDone?.areas?.[0]?._id,
              label:
                wDone?.areas?.[0]?.abbreviation + " " + wDone?.areas?.[0]?.name,
            });
            taskOpt?.push({
              value: wDone?.hourly_rate_tasks_estimations?.[0]?._id,
              label:
                wDone?.hourly_rate_tasks_estimations?.[0]?.hourly_rate_task?.[0]
                  ?.abbreviation +
                " " +
                wDone?.hourly_rate_tasks_estimations?.[0]?.hourly_rate_task?.[0]
                  ?.name,
            });
          });

          allData?.push({
            worker_id: sdetails?.worker_id,
            timesheet_status: sdetails?.timesheet_status,
            started: start_time,
            break: sdetails?.break,
            finished: finished_time,
            completion_type: "",
            payroll_category_id: sdetails?.payroll_category?.[0]?._id,
            worksite_timesheet_workdone: allworksite_timesheet_workdone,
            noteVisibility: sdetails?.notes ? true : false,
            notes: sdetails?.notes,
            shift_date: activeDate,
          });
        });

        setWorkerOption({
          ...workerOption,
          option: workOpt,
        });
        setParollCatOpt({
          ...workerOption,
          option: payCat,
        });
        setAreaOpt({
          ...workerOption,
          option: areaOpt,
        });
        setTaskOpt({
          ...workerOption,
          option: taskOpt,
        });

        setShiftDetails({
          ...shiftDetails,
          worker_data: allData,
          worksite_name: data?.data?.data?.[0]?.worksites?.[0]?.name,
          lastModifiedBy: data?.data?.data?.[0]?.lastModifiedBy,

          isShimmer: false,
        });

        return data;
      } else {
        return data;
      }
    },
    shiftDate: (e, index) => {
      shiftDetails.worker_data[index].shift_date = e?.target?.value;
      setShiftDetails({
        ...shiftDetails,
      });
    },
    getWorksite: async () => {
      const params = {
        id: id,
      };
      let data = await GetWorkSiteById(params);
      if (data?.status) {
        setShiftDetails({
          ...shiftDetails,
          worksite_name: data?.data?.data?.name,
          isShimmer: false,
        });
        if (summary) {
          let workOpt = [];
          workOpt?.push({
            value: workerId,
            label: workerName,
          });
          setWorkerOption({
            ...workerOption,
            option: workOpt,
          });
        }
      } else {
      }
    },
    getDetails: () => {
      if (summary) {
        navigate(`/admin/summary`);
      } else {
        navigate(
          `/admin/worksite/details/timesheet/${id}/${timesheet}/timesheet-details`
        );
      }
    },
    noteVisibility: (index) => {
      shiftDetails.worker_data[index].noteVisibility =
        !shiftDetails?.worker_data?.[index]?.noteVisibility;

      shiftDetails.worker_data[index].notes = "";
      setShiftDetails({
        ...shiftDetails,
      });
    },
    addWorkdone: (index) => {
      let allNewWorkdone = {
        area_id: "",
        task_id: "",
        spent: "",
      };

      let allWorkdone = [
        ...shiftDetails?.worker_data?.[index]?.worksite_timesheet_workdone,
      ];
      allWorkdone?.push(allNewWorkdone);

      shiftDetails.worker_data[index].worksite_timesheet_workdone = allWorkdone;
      setShiftDetails({ ...shiftDetails });
    },
    removeWorkdone: (index, ind) => {
      if (
        shiftDetails?.worker_data[index]?.worksite_timesheet_workdone?.[ind]?.id
      ) {
        console.log(
          shiftDetails?.worker_data[index]?.worksite_timesheet_workdone?.[ind]
            ?.id,
          "delete id"
        );
      } else {
        shiftDetails?.worker_data[index]?.worksite_timesheet_workdone?.splice(
          ind,
          1
        );
        setShiftDetails({ ...shiftDetails });
      }
    },
    selectWorker: async (id, index) => {
      shiftDetails.worker_data[index].worker_id = id;
      setShiftDetails({ ...shiftDetails });
    },

    searchWorker: async (e) => {
      const params = {
        search: e,
      };
      let data = await handleShiftDetails?.getWorker(params);
    },
    getWorker: async (selected) => {
      setWorkerOption({
        ...workerOption,
        option: [{ value: "loading", label: <ButtonSpinner /> }],
      });
      if (selected != "loading") {
        const params = {
          search: selected?.search,
          worksite_id: id,
        };
        let data = await GetWorkers(params);
        if (data?.status) {
          let opt = [];
          data?.data?.data?.map((wopt, index) => {
            opt?.push({
              value: wopt?.id,
              label: wopt?.name,
            });
          });
          console.log(data);
          setWorkerOption({
            ...workerOption,
            option: opt,
          });
        }
      }
    },
    shiftStatus: (id, index) => {
      shiftDetails.worker_data[index].timesheet_status = id;
      if (id != "completion_type") {
        shiftDetails.worker_data[index].completion_type = "";
      }
      setShiftDetails({ ...shiftDetails });
    },
    completion: (id, index) => {
      shiftDetails.worker_data[index].completion_type = id;
      setShiftDetails({ ...shiftDetails });
    },
    getStarted: (e, index) => {
      shiftDetails.worker_data[index].started = String(
        e?.target?.value
      )?.replace(":", ".");
      setShiftDetails({ ...shiftDetails });
    },
    break: (e, index) => {
      shiftDetails.worker_data[index].break = e;
      setShiftDetails({ ...shiftDetails });
    },
    finished: (e, index) => {
      shiftDetails.worker_data[index].finished = e?.target?.value?.replace(
        ":",
        "."
      );
      setShiftDetails({ ...shiftDetails });
    },
    searchPayrollCat: async (e) => {
      const params = {
        search: e,
      };
      let data = await handleShiftDetails?.getPayrollCat(params);
    },
    getPayrollCat: async (selected) => {
      setParollCatOpt({
        ...parollCatOpt,
        option: [{ value: "loading", label: <ButtonSpinner /> }],
      });

      if (selected != "loading") {
        const params = {
          search: selected?.search,
          worksite_id: id,
        };
        console.log(params);
        let data = await GetPayrollCatagory(params);
        if (data?.status) {
          let opt = [];
          data?.data?.data?.map((payCatopt, index) => {
            opt?.push({
              value: payCatopt?.id,
              label: payCatopt?.name,
            });
          });
          console.log(data);
          setParollCatOpt({
            ...parollCatOpt,
            option: opt,
          });
        }
      }
    },
    selectPayCat: async (id, index) => {
      console.log(id, index, "selectPayCat");
      shiftDetails.worker_data[index].payroll_category_id = id;
      setShiftDetails({ ...shiftDetails });
    },
    searchArea: async (e) => {
      const params = {
        search: e,
      };
      let data = await handleShiftDetails?.getArea(params);
    },
    getArea: async (selected) => {
      setAreaOpt({
        ...areasOpt,
        option: [{ value: "loading", label: <ButtonSpinner /> }],
      });

      if (selected != "loading") {
        const params = {
          search: selected?.search,
          worksite_id: id,
        };
        console.log(params);
        let data = await GetAreas(params);
        console.log(data, "data'data");
        if (data?.status) {
          console.log(data?.data?.data);
          let opt = [];
          data?.data?.data?.map((payCatopt, index) => {
            // if()
            opt?.push({
              value: payCatopt?._id,
              label: payCatopt?.abbreviation + " " + payCatopt?.name,
            });
          });
          console.log(data);
          setAreaOpt({
            ...areasOpt,
            option: opt,
          });
        }
      }
    },
    selectArea: (id, index, ind) => {
      shiftDetails.worker_data[index].worksite_timesheet_workdone[ind].area_id =
        id;
      setShiftDetails({ ...shiftDetails });
    },
    searchTask: async (e, index, ind) => {
      console.log(e);
      const params = {
        search: e,
      };
      let data = await handleShiftDetails?.getTasks(params, index, ind);
    },
    getTasks: async (selected, index, ind) => {
      const area_id =
        shiftDetails.worker_data[index].worksite_timesheet_workdone[ind]
          .area_id;

      setTaskOpt({
        ...taskOpt,
        option: [{ value: "loading", label: <ButtonSpinner /> }],
      });

      if (selected != "loading") {
        const params = {
          search: selected?.search,
          type: "hourly",
          worksite_id: id,
          status: "all",
          area_id: area_id,
        };
        let data = await GetTasks(params);
        if (data?.status) {
          console.log(data?.data?.data);
          let opt = [];
          data?.data?.data?.map((payCatopt, index) => {
            opt?.push({
              value: payCatopt?.id,
              label:
                payCatopt?.hourly_rate_task?.abbreviation +
                " " +
                payCatopt?.hourly_rate_task?.name,
            });
          });
          console.log(data);
          setTaskOpt({
            ...setTaskOpt,
            option: opt,
          });
        }
      }
    },
    selectTask: (id, index, ind) => {
      shiftDetails.worker_data[index].worksite_timesheet_workdone[ind].task_id =
        id;
      setShiftDetails({ ...shiftDetails });
    },
    spent: (e, index, ind) => {
      shiftDetails.worker_data[index].worksite_timesheet_workdone[ind].spent =
        e?.target?.value;
      setShiftDetails({ ...shiftDetails });
    },
    notes: async (e, index) => {
      shiftDetails.worker_data[index].notes = e?.target?.value;
      setShiftDetails({ ...shiftDetails });
    },
    submit: async () => {
      console.log("333nnjknk");
      shiftDetails?.worker_data?.map((allData, index) => {
        console.log("the saved data is the best data", allData);

        if (!allData?.worker_id) {
          console.log("1");
          setShiftDetails({
            ...shiftDetails,
            errors: {
              ...initialShiftDeatils?.errors,
              worker_id: "Select worker",
            },
          });
          worker_id?.current?.[index]?.current?.focus();
        } else if (shiftDetails?.isSave == 0 && !allData?.shift_date) {
          console.log("2");
          setShiftDetails({
            ...shiftDetails,
            errors: {
              ...initialShiftDeatils?.errors,
              shift_date: "Select shift date",
            },
          });
          shift_date?.current?.[index]?.current?.focus();
        } else if (!allData?.timesheet_status) {
          console.log("3");
          setShiftDetails({
            ...shiftDetails,
            errors: {
              ...initialShiftDeatils?.errors,
              timesheet_status: "Select shift Status",
            },
          });
          timesheet_status?.current?.[index]?.current?.focus();
        } else if (
          !allData?.completion_type &&
          allData?.timesheet_status == "Complete"
        ) {
          console.log("5");
          setShiftDetails({
            ...shiftDetails,
            errors: {
              ...initialShiftDeatils?.errors,
              completion_type: "Select shift Status",
            },
          });
          completion_type?.current?.[index]?.current?.focus();
        } else if (
          !allData?.started &&
          allData?.timesheet_status != "Rostered" &&
          allData?.timesheet_status != "Absent"
        ) {
          console.log("6");
          setShiftDetails({
            ...shiftDetails,
            errors: {
              ...initialShiftDeatils?.errors,
              started: "Select starting time",
            },
          });
          started?.current?.[index]?.current?.focus();
        } else if (
          !allData?.break &&
          allData?.timesheet_status != "Rostered" &&
          allData?.timesheet_status != "Absent"
        ) {
          console.log("7");
          setShiftDetails({
            ...shiftDetails,
            errors: {
              ...initialShiftDeatils?.errors,
              break_type: "Select break type",
            },
          });
          break_type?.current?.[index]?.current?.focus();
        } else if (
          !allData?.finished &&
          allData?.timesheet_status != "Rostered" &&
          allData?.timesheet_status != "Absent"
        ) {
          console.log("9");
          setShiftDetails({
            ...shiftDetails,
            errors: {
              ...initialShiftDeatils?.errors,
              finished: "Select finish time",
            },
          });
          finished?.current?.[index]?.current?.focus();
        } else if (
          !allData?.payroll_category_id &&
          allData?.timesheet_status != "Rostered" &&
          allData?.timesheet_status != "Absent"
        ) {
          console.log("10");
          setShiftDetails({
            ...shiftDetails,
            errors: {
              ...initialShiftDeatils?.errors,
              payroll_category_id: "Select payroll category",
            },
          });
          payroll_category_id?.current?.[index]?.current?.focus();
        } else {
          let errorCount = 0;

          allData?.worksite_timesheet_workdone?.map((wDone, ind) => {
            if (
              errorCount == ind &&
              errorCount != allData?.worksite_timesheet_workdone?.length
            ) {
              if (
                !wDone?.area_id &&
                allData?.timesheet_status != "Rostered" &&
                allData?.timesheet_status != "Absent"
              ) {
                setShiftDetails({
                  ...shiftDetails,
                  errors: {
                    ...initialShiftDeatils?.errors,
                    area_id: "Select area",
                    errorIndex: ind,
                  },
                });
                area_id?.current?.["area_id" + ind]?.current?.focus();
                console.log("area_id" + ind);
              } else if (
                !wDone?.task_id &&
                allData?.timesheet_status != "Rostered" &&
                allData?.timesheet_status != "Absent"
              ) {
                setShiftDetails({
                  ...shiftDetails,
                  errors: {
                    ...initialShiftDeatils?.errors,
                    task_id: "Select task",
                    errorIndex: ind,
                  },
                });
                task_id?.current?.["task_id" + ind]?.current?.focus();
              } else if (
                !wDone?.spent &&
                allData?.timesheet_status != "Rostered" &&
                allData?.timesheet_status != "Absent"
              ) {
                setShiftDetails({
                  ...shiftDetails,
                  errors: {
                    ...initialShiftDeatils?.errors,
                    spent: "Select spent",
                    errorIndex: ind,
                  },
                });
                spent?.current?.["spent" + ind]?.current?.focus();
              } else {
                errorCount++;
                if (
                  errorCount == allData?.worksite_timesheet_workdone?.length
                ) {
                  handleShiftDetails?.save();
                }
              }
            }
          });
        }
      });
    },
    save: async () => {
      console.log("hiiii", shiftDetails);
      const isValid = shiftDetails.worker_data.every((worker) => {
        const startedTime = worker.started.split("."); // Split HH:MM for the current worker
        const finishedTime = worker.finished.split("."); // Split HH:MM for the current worker

        // Convert to minutes
        const startedMinutes =
          parseInt(startedTime[0]) * 60 + parseInt(startedTime[1]);
        const finishedMinutes =
          parseInt(finishedTime[0]) * 60 + parseInt(finishedTime[1]);

        // Calculate difference in minutes
        const difference = finishedMinutes - startedMinutes;
        const differenceInHours = difference / 60;

        // Validate spent
        return (
          worker.worksite_timesheet_workdone?.[0]?.spent <= differenceInHours &&
          worker.worksite_timesheet_workdone?.[0]?.spent >= 0
        ); // Check for positive spent
      });

      if (
        shiftDetails.worker_data?.[0]?.timesheet_status !== "Absent" &&
        !isValid
      ) {
        toast?.error("Some spent values exceed the shift length time.");
        return; // Exit if validation fails
      }

      if (shiftDetails?.isSave == 1) {
        setShiftDetails({
          ...shiftDetails,
          isLoading: true,
          errors: {
            ...initialShiftDeatils?.errors,
          },
        });

        let data = await CreateShiftDetails(shiftDetails);
        if (data?.status) {
          toast?.success(data?.message);
          setShiftDetails({
            ...shiftDetails,
            isLoading: false,
          });
          if (summary) {
            navigate(`/admin/summary`);
          } else {
            navigate(
              `/admin/worksite/details/timesheet/${id}/${timesheet}/timesheet-details`
            );
          }
        } else {
          toast?.error(data?.message);
          setShiftDetails({
            ...shiftDetails,
            isLoading: false,
          });
        }
      } else {
        setShiftDetails({
          ...shiftDetails,
          isLoading: true,
          errors: {
            ...initialShiftDeatils?.errors,
          },
        });

        let data = await EditShiftDetails(shiftDetails);
        if (data?.status) {
          toast?.success(data?.message);
          setShiftDetails({
            ...shiftDetails,
            isLoading: false,
          });
          navigate(
            `/admin/worksite/details/timesheet/${id}/${timesheet}/timesheet-details`
          );
        } else {
          toast?.error(data?.message);
          setShiftDetails({
            ...shiftDetails,
            isLoading: false,
          });
        }
      }
    },
  };

  useEffect(() => {
    (async () => {
      if (shift) {
        const params = {
          id: shift,
          worksheet_id: timesheet,
          cursor: "",
          take: "",
          previous: "",
          search: "",
        };
        let data = await handleShiftDetails?.getShift(params);
      } else {
        console.log(shift, "else");
        let data = await handleShiftDetails?.getWorksite();
      }
    })();
  }, []);

  return (
    <form>
      <div className="flex flex-col w-fit min-w-[60%] my-5 mx-auto">
        <div className="bg-white text-lg cursor-pointer p-8">
          {shiftDetails?.isShimmer ? (
            <TextShimmer data={{ gap: 15, line: 20 }} />
          ) : (
            <Fragment>
              <div className="flex" onClick={handleShiftDetails?.getDetails}>
                <div>
                  <div className="pt-1 pr-2 ">
                    <FaArrowLeft />
                  </div>
                </div>

                <div className="font-semibold">
                  {path?.pathname ==
                  `/admin/worksite/edit-timesheet-details/${id}/${timesheet}/${shift}`
                    ? "Edit "
                    : "Add "}
                  Shift Details
                </div>
              </div>

              {shiftDetails?.isSave == 0 && (
                <div className="my-4">
                  <div className="text-md">Last Modified By</div>
                  <div className="text-sm py-2">
                    {shiftDetails?.lastModifiedBy?.length > 0
                      ? shiftDetails.lastModifiedBy.map((item) => item?.name)
                      : "N/A"}
                  </div>
                </div>
              )}

              <div className="my-4">
                <div className="text-md">Worksite</div>
                <div className="text-sm py-2">
                  {shiftDetails?.worksite_name}
                </div>
              </div>

              {shiftDetails?.worker_data?.map((sdetails, index) => {
                worker_id.current[index] = React.createRef();
                timesheet_status.current[index] = React.createRef();
                started.current[index] = React.createRef();
                completion_type.current[index] = React.createRef();
                shift_date.current[index] = React.createRef();
                finished.current[index] = React.createRef();
                break_type.current[index] = React.createRef();
                payroll_category_id.current[index] = React.createRef();

                return (
                  <div>
                    <div className="my-4">
                      <label
                        htmlFor="worker_id"
                        className="text-md"
                        ref={worker_id?.current[index]}
                      >
                        Worker
                      </label>

                      {shiftDetails?.isSave == 0 || summary ? (
                        <div className="text-sm py-2">
                          {workerOption?.option?.[0]?.label}
                        </div>
                      ) : (
                        <div>
                          <Select
                            showSearch
                            id="worker_id"
                            name="worker_id"
                            placeholder="Select worker"
                            className="w-full py-2"
                            optionFilterProp="children"
                            filterOption={false}
                            status={
                              shiftDetails?.errors?.worker_id ? "error" : ""
                            }
                            value={sdetails?.worker_id}
                            options={workerOption?.option}
                            onFocus={handleShiftDetails?.getWorker}
                            onChange={(e) =>
                              handleShiftDetails?.selectWorker(e, index)
                            }
                            onSearch={handleShiftDetails?.searchWorker}
                          />
                          {shiftDetails?.errors?.worker_id && (
                            <p className="pt-2 text-red-600 text-sm">
                              {shiftDetails?.errors?.worker_id}
                            </p>
                          )}
                        </div>
                      )}
                    </div>

                    {shiftDetails?.isSave == 0 && (
                      <div className="my-4">
                        <label htmlFor="timesheet_status" className="text-md">
                          Shift Date
                        </label>
                        <div className="py-2">
                          <div className="py-2">
                            <label htmlFor="shift_date" className="text-md ">
                              Date
                            </label>
                          </div>
                          <Input
                            id="shift_date"
                            name="shift_date"
                            ref={shift_date?.current[index]}
                            classNames="py-2"
                            type="date"
                            placeholder="Select from"
                            className={
                              shiftDetails?.errors?.shift_date
                                ? " border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                                : ""
                            }
                            value={sdetails?.shift_date}
                            onChange={(e) =>
                              handleShiftDetails?.shiftDate(e, index)
                            }
                          />
                          {shiftDetails?.errors?.shift_date && (
                            <p className="pt-2 text-red-600 text-sm">
                              {shiftDetails?.errors?.shift_date}
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="my-4">
                      <div className="flex justify-between">
                        <div className="w-full">
                          <div className="">
                            <label
                              htmlFor="timesheet_status"
                              className="text-md"
                            >
                              Shift Status
                            </label>
                          </div>
                          <div>
                            <div className="pr-2">
                              <Select
                                showSearch
                                id="timesheet_status"
                                name="timesheet_status"
                                placeholder="Select a shift status"
                                optionFilterProp="children"
                                className="py-2 w-full"
                                ref={timesheet_status?.current[index]}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                status={
                                  shiftDetails?.errors?.timesheet_status
                                    ? "error"
                                    : ""
                                }
                                value={sdetails?.timesheet_status}
                                onChange={(e) =>
                                  handleShiftDetails?.shiftStatus(e, index)
                                }
                                options={shiftDetailsOption}
                              />
                              {shiftDetails?.errors?.timesheet_status && (
                                <p className="pt-2 text-red-600 text-sm">
                                  {shiftDetails?.errors?.timesheet_status}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        {sdetails?.timesheet_status == "Complete" && (
                          <div className="w-full">
                            <div>
                              <label
                                htmlFor="completion_type"
                                className="text-md"
                              >
                                Completion Type
                              </label>
                            </div>
                            <div>
                              <div className="w-full">
                                <Select
                                  showSearch
                                  id="completion_type"
                                  name="completion_type"
                                  placeholder="Select a shift status"
                                  optionFilterProp="children"
                                  className="w-full py-2"
                                  ref={completion_type?.current[index]}
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  status={
                                    shiftDetails?.errors?.completion_type
                                      ? "error"
                                      : ""
                                  }
                                  value={sdetails?.completion_type}
                                  onChange={(e) =>
                                    handleShiftDetails?.completion(e, index)
                                  }
                                  options={completionOpt}
                                />
                                {shiftDetails?.errors?.completion_type && (
                                  <p className="pt-2 text-red-600 text-sm">
                                    {shiftDetails?.errors?.completion_type}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {sdetails?.timesheet_status != "Rostered" &&
                      sdetails?.timesheet_status != "Absent" && (
                        <>
                          <div>
                            <div className="py-3">
                              <label>Shift Length</label>
                              <Tooltip title="The starting time must not be earlier than the finishing time.">
                                <span className="ml-2 fs-4">
                                  <InfoCircleOutlined />
                                </span>
                              </Tooltip>
                            </div>
                            <div className="flex justify-between">
                              <div className="w-full">
                                <div>
                                  <label htmlFor="started" className="text-sm">
                                    Started
                                  </label>
                                </div>
                                <div>
                                  <Input
                                    type="time"
                                    id="started"
                                    name="started"
                                    ref={started?.current[index]}
                                    className={
                                      shiftDetails?.errors?.started
                                        ? " border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                                        : ""
                                    }
                                    value={sdetails?.started?.replace(".", ":")}
                                    onChange={(e) =>
                                      handleShiftDetails?.getStarted(e, index)
                                    }
                                  />
                                  {shiftDetails?.errors?.started && (
                                    <p className="pt-2 text-red-600 text-sm">
                                      {shiftDetails?.errors?.started}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="w-full px-4">
                                <div>
                                  <div>
                                    <label
                                      htmlFor="break"
                                      className="text-sm font-lexend mb-1"
                                    >
                                      Break
                                    </label>
                                  </div>
                                  <div>
                                    <Select
                                      showSearch
                                      name="break"
                                      id="break"
                                      placeholder="Select break"
                                      optionFilterProp="children"
                                      ref={break_type?.current[index]}
                                      status={
                                        shiftDetails?.errors?.break_type
                                          ? "error"
                                          : ""
                                      }
                                      className="w-[250px]"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                      value={sdetails?.break}
                                      onChange={(e) =>
                                        handleShiftDetails?.break(e, index)
                                      }
                                      options={breakOption}
                                    />
                                    {shiftDetails?.errors?.break_type && (
                                      <p className="pt-2 text-red-600 text-sm">
                                        {shiftDetails?.errors?.break_type}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="w-full">
                                <div>
                                  <div>
                                    <label
                                      htmlFor="finished"
                                      className="text-sm font-lexend mb-1"
                                    >
                                      Finished
                                    </label>
                                  </div>
                                  <div>
                                    <Input
                                      type="time"
                                      id="finished"
                                      ref={finished?.current[index]}
                                      name="finished"
                                      className={
                                        shiftDetails?.errors?.finished
                                          ? "h-full border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                                          : "h-full"
                                      }
                                      value={sdetails?.finished?.replace(
                                        ".",
                                        ":"
                                      )}
                                      onChange={(e) =>
                                        handleShiftDetails?.finished(e, index)
                                      }
                                    />
                                    {shiftDetails?.errors?.finished && (
                                      <p className="pt-2 text-red-600 text-sm">
                                        {shiftDetails?.errors?.finished}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="py-6">
                              <label
                                htmlFor="payroll_category_id"
                                ref={payroll_category_id?.current[index]}
                              >
                                Payroll Category
                              </label>
                              <Select
                                showSearch
                                id="payroll_category_id"
                                name="payroll_category_id"
                                placeholder="Select payroll category"
                                className="w-full py-2"
                                optionFilterProp="children"
                                filterOption={false}
                                status={
                                  shiftDetails?.errors?.payroll_category_id
                                    ? "error"
                                    : ""
                                }
                                value={sdetails?.payroll_category_id}
                                options={parollCatOpt?.option}
                                onFocus={handleShiftDetails?.getPayrollCat}
                                onChange={(e) =>
                                  handleShiftDetails?.selectPayCat(e, index)
                                }
                                onSearch={handleShiftDetails?.searchPayrollCat}
                              />
                              {shiftDetails?.errors?.payroll_category_id && (
                                <p className="pt-2 text-red-600 text-sm">
                                  {shiftDetails?.errors?.payroll_category_id}
                                </p>
                              )}
                            </div>

                            {!shiftDetails?.isInduction && (
                              <div className="my-8">
                                <div className="">
                                  No Casual Rates Available
                                </div>
                                <div className="text-slate-700 py-3">
                                  Before a shift can be created for Workers, you
                                  will need to assign at least one Casual rate
                                  to
                                </div>
                                <div>DOCKLANDS - PRAVEEN TESTING.</div>
                                <div className="py-3">
                                  <div className="flex border-b-2 py-2  justify-between border-slate-200">
                                    <div>Admin Salary</div>
                                    <div className="pt-1">
                                      <FaTrash />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="text-center">
                                    <Button className="my-5 ml-auto btn btn-blue rounded  w-1/5 h-11">
                                      Assign
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div>
                            <label htmlFor="">Work Done</label>

                            <table className="w-full">
                              <thead className="border border-white border-b-[#DBE4EC] border-4 text-center">
                                <tr className="text-left">
                                  <th className="py-2 w-[20%]">
                                    <div className="flex items-center justify-center">
                                      Area
                                    </div>
                                  </th>
                                  <th className="py-2 w-[40%]">
                                    <div className="flex items-center justify-center">
                                      Task
                                    </div>
                                  </th>
                                  <th className="py-2 w-[30%]">
                                    <div className="flex items-center justify-center">
                                      Spent
                                    </div>
                                  </th>
                                  <th className="py-2 w-[10%]">
                                    <div className="flex items-center justify-center">
                                      Actions
                                    </div>
                                  </th>
                                </tr>
                              </thead>

                              <tbody className="bg-white">
                                {sdetails?.worksite_timesheet_workdone?.map(
                                  (workdone, ind) => {
                                    area_id.current["area_id" + ind] =
                                      React.createRef();
                                    task_id.current["task_id" + ind] =
                                      React.createRef();
                                    spent.current["spent" + ind] =
                                      React.createRef();

                                    return (
                                      <tr
                                        className="border-b border-[#F0F0F0]"
                                        key={ind}
                                      >
                                        <td className="text-center w-[20%]">
                                          <div className="">
                                            <label
                                              htmlFor={`area_id` + ind}
                                              ref={
                                                area_id.current["area_id" + ind]
                                              }
                                            />

                                            <Select
                                              showSearch
                                              id={`area_id` + ind}
                                              name="area_id"
                                              placeholder="Select area"
                                              status={
                                                shiftDetails?.errors?.area_id &&
                                                shiftDetails?.errors
                                                  ?.errorIndex == ind
                                                  ? "error"
                                                  : ""
                                              }
                                              style={{
                                                width: 200,
                                              }}
                                              optionFilterProp="children"
                                              filterOption={false}
                                              value={workdone?.area_id}
                                              options={areasOpt?.option}
                                              onChange={(e) =>
                                                handleShiftDetails?.selectArea(
                                                  e,
                                                  index,
                                                  ind
                                                )
                                              }
                                              onSearch={
                                                handleShiftDetails?.searchArea
                                              }
                                              onFocus={
                                                handleShiftDetails?.getArea
                                              }
                                            />
                                            {shiftDetails?.errors?.area_id &&
                                              shiftDetails?.errors
                                                ?.errorIndex == ind && (
                                                <p className="pt-2 text-red-600 text-sm">
                                                  {
                                                    shiftDetails?.errors
                                                      ?.area_id
                                                  }
                                                </p>
                                              )}
                                          </div>
                                        </td>
                                        <td className="px-4 py-3">
                                          {workdone?.area_id ? (
                                            <>
                                              <div className="">
                                                <label
                                                  htmlFor={`task_id` + ind}
                                                  ref={
                                                    task_id.current[
                                                      "task_id" + ind
                                                    ]
                                                  }
                                                />
                                                <Select
                                                  showSearch
                                                  id={`task_id` + ind}
                                                  name="task_id"
                                                  placeholder="Select task"
                                                  status={
                                                    shiftDetails?.errors
                                                      ?.task_id &&
                                                    shiftDetails?.errors
                                                      ?.errorIndex == ind
                                                      ? "error"
                                                      : ""
                                                  }
                                                  style={{
                                                    width: 200,
                                                  }}
                                                  optionFilterProp="children"
                                                  filterOption={false}
                                                  value={workdone?.task_id}
                                                  options={taskOpt?.option}
                                                  onChange={(e) =>
                                                    handleShiftDetails?.selectTask(
                                                      e,
                                                      index,
                                                      ind
                                                    )
                                                  }
                                                  onSearch={(e) =>
                                                    handleShiftDetails?.searchTask(
                                                      e,
                                                      index,
                                                      ind
                                                    )
                                                  }
                                                  onFocus={(selected) =>
                                                    handleShiftDetails?.getTasks(
                                                      selected,
                                                      index,
                                                      ind
                                                    )
                                                  }
                                                />
                                                {shiftDetails?.errors
                                                  ?.task_id &&
                                                  shiftDetails?.errors
                                                    ?.errorIndex == ind && (
                                                    <p className="pt-2 text-red-600 text-sm">
                                                      {
                                                        shiftDetails?.errors
                                                          ?.task_id
                                                      }
                                                    </p>
                                                  )}
                                              </div>
                                            </>
                                          ) : (
                                            <div className="w-[200px]" />
                                          )}
                                        </td>

                                        <td className="px-4 py-3">
                                          <div className="">
                                            <Input
                                              id="spent"
                                              name="spent"
                                              value={workdone?.spent}
                                              ref={
                                                spent?.current["spent" + ind]
                                              }
                                              onChange={(e) =>
                                                handleShiftDetails?.spent(
                                                  e,
                                                  index,
                                                  ind
                                                )
                                              }
                                              className={
                                                shiftDetails?.errors?.spent &&
                                                shiftDetails?.errors
                                                  ?.errorIndex == ind
                                                  ? "border-1 border-rose-600 hover:border-rose-600 focus:border-rose-600"
                                                  : ""
                                              }
                                              autoComplete="off"
                                            />
                                            {shiftDetails?.errors?.spent &&
                                              shiftDetails?.errors
                                                ?.errorIndex == ind && (
                                                <p className="pt-2 text-red-600 text-sm">
                                                  {shiftDetails?.errors?.spent}
                                                </p>
                                              )}
                                          </div>
                                        </td>

                                        <td className="px-4 py-3">
                                          <div className="flex items-center justify-center">
                                            <BsTrash3
                                              onClick={() =>
                                                handleShiftDetails?.removeWorkdone(
                                                  index,
                                                  ind
                                                )
                                              }
                                              className="cursor-pointer"
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>

                            <Button
                              className="mt-4 max-w-[20%] ml-auto flex justify-center items-center py-3 border border-[#111111] h-[42px]"
                              onClick={() =>
                                handleShiftDetails?.addWorkdone(index)
                              }
                            >
                              Add
                              <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                                <FaPlus />
                              </div>
                            </Button>
                          </div>
                        </>
                      )}

                    <div>
                      <div className="my-4">
                        <div className="flex justify-between pt-4">
                          <div className="">Payroll Notes</div>
                          <div
                            className="text-[#0F4C7D]"
                            onClick={() =>
                              handleShiftDetails?.noteVisibility(index)
                            }
                          >
                            {sdetails?.noteVisibility ? "Remove" : "+Add"}
                          </div>
                        </div>
                      </div>

                      {sdetails?.noteVisibility && (
                        <div className="">
                          <TextArea
                            rows={5}
                            onChange={(e) =>
                              handleShiftDetails?.notes(e, index)
                            }
                            value={sdetails?.notes}
                          />
                        </div>
                      )}

                      {!shiftDetails?.isInduction && (
                        <div className="my-8 border border-[#0F4C7D] rounded-md">
                          <div className="px-6 py-3">
                            <div className="flex text-xl pb-2 justify-between">
                              <div className="">Induction in progress</div>
                              <div>0%</div>
                            </div>
                            <div className="text-slate-700">
                              John Smith is currently being inducted.
                            </div>
                          </div>
                          <div className="bg-[#0F4C7D] text-white text-sm">
                            <div className="text-center	py-2">
                              Creating this shift stop their induction, are you
                              sure you to continue?
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </Fragment>
          )}
        </div>

        <Button
          className="my-5 ml-auto btn-blue  bg-blue-disable rounded  w-1/5 h-11"
          onClick={handleShiftDetails?.submit}
          disabled={shiftDetails?.isLoading}
        >
          {shiftDetails?.isLoading ? <ButtonSpinner /> : "Save Changes"}
        </Button>
      </div>
    </form>
  );
};

export default AddTimesheetDetails;
